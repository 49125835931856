import React from 'react';
import './Loading.css'
const Loading = () => {
    return (
        <div className='mt-96'>
            <span className="loader"></span>

        </div>
    );
};

export default Loading;